document.addEventListener('DOMContentLoaded', () => {
    const passwordInput = document.getElementById('newPassword') as HTMLInputElement | null;
    
    if (!passwordInput) {
        return;
    }

    const form = passwordInput.closest('form') as HTMLFormElement | null;
    
    if (!form) {
        return;
    }

    const ERROR_LIST_ID = 'password-validation-errors';
    const STRENGTH_INDICATOR_ID = 'password-strength';

    let hasSubmitted = false;

    const minLength = parseInt(form.dataset.minLength || '8', 10);
    const maxLength = parseInt(form.dataset.maxLength || '160', 10);
    const requireCases = form.dataset.requireCases === 'true';
    const requireNumbers = form.dataset.requireNumbers === 'true';
    const requireSymbols = form.dataset.requireSymbols === 'true';
    const showStrength = form.dataset.showStrength === 'true';

    const passwordCriteria = [
        { regex: new RegExp(`.{${minLength},}`), message: form.dataset.messageMinLength!.replace('{n}', minLength.toString()) },
        { regex: new RegExp(`^.{0,${maxLength}}$`), message: form.dataset.messageMaxLength!.replace('{n}', maxLength.toString()) },
    ];

    if (requireCases) {
        passwordCriteria.push(
            { regex: /[a-z]/, message: form.dataset.messageLowercase! },
            { regex: /[A-Z]/, message: form.dataset.messageUppercase! }
        );
    }

    if (requireNumbers) {
        passwordCriteria.push({ regex: /[0-9]/, message: form.dataset.messageNumbers! });
    }

    if (requireSymbols) {
        passwordCriteria.push({ regex: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, message: form.dataset.messageSymbols! });
    }

    const validatePassword = (password: string): string[] => {
        if (password.length === 0) {
            return [];
        }
        return passwordCriteria
            .filter(criterion => !criterion.regex.test(password))
            .map(criterion => criterion.message);
    };

    const displayErrors = (errors: string[]): void => {
        let errorsList = document.getElementById(ERROR_LIST_ID);
        
        if (!errorsList) {
            errorsList = document.createElement('ul');
            errorsList.id = ERROR_LIST_ID;
            errorsList.className = 'password-errors errors';
            passwordInput.parentNode?.insertBefore(errorsList, passwordInput.nextSibling);
        } else {
            errorsList.className = 'password-errors errors';
        }

        errorsList.innerHTML = errors.map(error => `<li>${error}</li>`).join('');
        
        passwordInput.setAttribute('aria-invalid', 'true');
        passwordInput.setAttribute('aria-describedby', getDescribedByValue());
    };

    const clearErrors = (): void => {
        const errorsList = document.getElementById(ERROR_LIST_ID);
        if (errorsList) {
            errorsList.remove();
        }
        
        passwordInput.setAttribute('aria-invalid', 'false');
        passwordInput.setAttribute('aria-describedby', getDescribedByValue());
    };

    const getDescribedByValue = (): string => {
        const ids: string[] = [];
        if (document.getElementById(ERROR_LIST_ID)) {
            ids.push(ERROR_LIST_ID);
        }
        if (document.getElementById(STRENGTH_INDICATOR_ID)) {
            ids.push(STRENGTH_INDICATOR_ID);
        }
        return ids.join(' ');
    };

    const showStrengthIndicator = (password: string): void => {
        if (!showStrength) return;

        let strengthIndicator = document.getElementById(STRENGTH_INDICATOR_ID);
        if (password.length === 0) {
            if (strengthIndicator) {
                strengthIndicator.remove();
                passwordInput.setAttribute('aria-describedby', getDescribedByValue());
            }
            return;
        }

        let strength = 0;
        if (password.length >= minLength) strength++;
        if (password.length >= minLength + 4) strength++;
        if (/[A-Z]/.test(password)) strength++;
        if (/[a-z]/.test(password)) strength++;
        if (/[0-9]/.test(password)) strength++;
        if (/[^A-Za-z0-9]/.test(password)) strength++;

        let strengthText = '';
        switch (strength) {
            case 0:
            case 1:
                strengthText = form.dataset.messageStrengthWeak!;
                break;
            case 2:
            case 3:
                strengthText = form.dataset.messageStrengthMedium!;
                break;
            case 4:
            case 5:
                strengthText = form.dataset.messageStrengthStrong!;
                break;
            default:
                strengthText = form.dataset.messageStrengthVeryStrong!;
        }

        if (!strengthIndicator) {
            strengthIndicator = document.createElement('div');
            strengthIndicator.id = STRENGTH_INDICATOR_ID;
            passwordInput.parentNode?.insertBefore(strengthIndicator, passwordInput.nextSibling);
        }
        strengthIndicator.textContent = form.dataset.messagePasswordStrength!.replace('{strength}', strengthText);
        strengthIndicator.className = `password-strength ${strengthText.toLowerCase().replace(' ', '-')}`;
        
        passwordInput.setAttribute('aria-describedby', getDescribedByValue());
    };

    const initialErrorsList = document.getElementById(ERROR_LIST_ID);
    if (initialErrorsList) {
        passwordInput.setAttribute('aria-invalid', 'true');
        passwordInput.setAttribute('aria-describedby', getDescribedByValue());
    }

    const validateOnInput = () => {
        const password = passwordInput.value;
        const errors = validatePassword(password);

        if (errors.length > 0) {
            displayErrors(errors);
        } else {
            clearErrors();
        }

        showStrengthIndicator(password);
    };

    const startLiveValidation = () => {
        if (!hasSubmitted) {
            hasSubmitted = true;
            passwordInput.addEventListener('keyup', validateOnInput);
        }
    };

    form.addEventListener('submit', (e: Event) => {
        const password = passwordInput.value;
        if (password.length > 0) {
            const errors = validatePassword(password);
            if (errors.length > 0) {
                e.preventDefault();
                displayErrors(errors);
                showStrengthIndicator(password);
                startLiveValidation();
            } else {
                clearErrors();
            }
        } else {
            clearErrors();
        }
    });

    if (passwordInput.value.length > 0) {
        showStrengthIndicator(passwordInput.value);
    }
});