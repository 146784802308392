import { initCircledFocus } from '@ts/utils/circled-focus';
import { translate } from './language';
import { logoutUser } from './logout';
import { resetInactivityTimer } from './index';

const INITIAL_WARNING_TIME: number = 119 * 1000; // 119 seconds in milliseconds
let warningTime: number = INITIAL_WARNING_TIME;
let warningStartTime: number | null = null;
let remainingTime: number = warningTime;
let animationFrameId: number | null = null;

export function showWarning(): void {
    if (document.querySelector('.inactivity-timer')) {
        return;
    }

    const modal: HTMLDivElement = document.createElement('div');
    modal.className = 'inactivity-timer';
    modal.setAttribute('role', 'dialog');
    modal.setAttribute('aria-modal', 'true');
    modal.setAttribute('aria-labelledby', 'inactivityTimerTitle');
    modal.tabIndex = -1;
    modal.innerHTML = `
    <div class="inactivity-timer__modal">
      <h2 id="inactivityTimerTitle" class="inactivity-timer__title">${translate('title')}</h2>
      <p class="inactivity-timer__message">${translate('message1')}</p>
      <p class="inactivity-timer__message"><strong><span id="countdown">${Math.ceil(remainingTime / 1000)}</span> ${translate('message2')}</strong>.</p>
      <div class="inactivity-timer__button-container">
        <button id="stayConnected" class="btn btn--gray-30 btn--center inactivity-timer__button">${translate('stayConnected')}</button>
        <button id="logout" class="btn btn--center inactivity-timer__button">${translate('disconnect')}</button>
      </div>
    </div>
    `;

    document.body.insertBefore(modal, document.body.firstChild);

    const removeCircledFocus = initCircledFocus(modal);

    const countdownElement: HTMLElement | null = document.getElementById('countdown');

    function updateCountdown(timestamp: number): void {
        if (warningStartTime === null) {
            warningStartTime = timestamp;
        }

        const elapsedTime = timestamp - warningStartTime;
        remainingTime = Math.max(0, warningTime - elapsedTime);

        if (countdownElement) {
            countdownElement.textContent = Math.ceil(remainingTime / 1000).toString();
        }

        if (remainingTime <= 0) {
            logoutUser();
        } else {
            animationFrameId = requestAnimationFrame(updateCountdown);
        }
    }

    function handleVisibilityChange(): void {
        if (document.hidden) {
            if (animationFrameId !== null) {
                cancelAnimationFrame(animationFrameId);
                animationFrameId = null;
            }
            const hiddenStartTime = performance.now();
            document.addEventListener('visibilitychange', function onVisible() {
                if (!document.hidden) {
                    document.removeEventListener('visibilitychange', onVisible);
                    const hiddenDuration = performance.now() - hiddenStartTime;
                    remainingTime = Math.max(0, remainingTime - hiddenDuration);
                    warningStartTime = null; // Reset start time
                    if (remainingTime <= 0) {
                        logoutUser();
                    } else {
                        animationFrameId = requestAnimationFrame(updateCountdown);
                    }
                }
            });
        }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    animationFrameId = requestAnimationFrame(updateCountdown);

    function handleModalInteraction(event: MouseEvent): void {
        const modalContent: HTMLElement | null = modal.querySelector('.inactivity-timer__modal');
        if (!modalContent?.contains(event.target as Node)) {
            const stayConnectedButton: HTMLElement | null = document.getElementById('stayConnected');
            if (stayConnectedButton) {
                stayConnectedButton.click();
            }
        }
    }

    document.addEventListener('click', handleModalInteraction, true);

    function closeModal(): void {
        if (animationFrameId !== null) {
            cancelAnimationFrame(animationFrameId);
        }
        removeCircledFocus();
        document.body.removeChild(modal);
        document.removeEventListener('click', handleModalInteraction, true);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        
        // Reset warning time and related variables
        warningTime = INITIAL_WARNING_TIME;
        remainingTime = warningTime;
        warningStartTime = null;
        
        resetInactivityTimer(); // Reset the inactivity timer in index.ts
    }

    const stayConnectedButton: HTMLElement | null = document.getElementById('stayConnected');
    if (stayConnectedButton) {
        stayConnectedButton.addEventListener('click', closeModal);
    }

    const logoutButton: HTMLElement | null = document.getElementById('logout');
    if (logoutButton) {
        logoutButton.addEventListener('click', () => {
            closeModal();
            logoutUser();
        });
    }

    modal.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            closeModal();
        }
    });

    modal.focus();
    window.scrollTo(0, 0);
}

// Check logout attempt when user switches to old tab
export function checkAndAttemptLogout(): void {
    const countdownElement = document.getElementById('countdown');
    if (countdownElement && (countdownElement.textContent === '0' || countdownElement.textContent === '119')) {
        logoutUser();
    }
}

document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
        checkAndAttemptLogout();
    }
});

window.addEventListener('load', checkAndAttemptLogout);