document.addEventListener('DOMContentLoaded', () => {
    const galleryPopup = document.querySelector('.gallery-popup') as HTMLElement;
    if (!galleryPopup) return;

    const galleryImage = galleryPopup.querySelector('.gallery-popup__image') as HTMLImageElement;
    const prevButton = galleryPopup.querySelector('.gallery-popup__nav--prev') as HTMLButtonElement;
    const nextButton = galleryPopup.querySelector('.gallery-popup__nav--next') as HTMLButtonElement;
    const closeButton = galleryPopup.querySelector('.gallery-popup__close') as HTMLButtonElement;

    let currentImageIndex = 0;
    let currentGalleryImages: HTMLImageElement[] = [];
    let currentGalleryButtons: HTMLButtonElement[] = [];

    let touchStartX = 0;
    let touchEndX = 0;

    const focusableElements = [closeButton, prevButton, nextButton];
    let currentFocusIndex = 0;

    const openGalleryPopup = (galleryId: string, index: number): void => {
        currentGalleryImages = Array.from(document.querySelectorAll<HTMLImageElement>(
            `.text-image__photo-gallery[data-gallery-id="${galleryId}"] .text-image__gallery-thumbnail`
        ));
        currentGalleryButtons = Array.from(document.querySelectorAll<HTMLButtonElement>(
            `.text-image__photo-gallery[data-gallery-id="${galleryId}"] .text-image__gallery-item`
        ));
        currentImageIndex = index;
        updateGalleryImage();
        galleryPopup.classList.add('is-active');
        galleryPopup.setAttribute('aria-hidden', 'false');
        currentFocusIndex = 0;
        focusableElements[currentFocusIndex].focus();
    };

    const closeGalleryPopup = (): void => {
        galleryPopup.classList.remove('is-active');
        galleryPopup.setAttribute('aria-hidden', 'true');
        if (currentGalleryButtons[currentImageIndex]) {
            currentGalleryButtons[currentImageIndex].focus();
        }
    };

    const updateGalleryImage = (): void => {
        const currentImage = currentGalleryImages[currentImageIndex];
        galleryImage.src = currentImage.dataset.fullImage ?? '';
        galleryImage.alt = currentImage.alt;
        galleryImage.setAttribute('aria-label', `Image ${currentImageIndex + 1} of ${currentGalleryImages.length}: ${currentImage.alt}`);
    };

    const showNextImage = (): void => {
        currentImageIndex = (currentImageIndex + 1) % currentGalleryImages.length;
        updateGalleryImage();
    };

    const showPrevImage = (): void => {
        currentImageIndex = (currentImageIndex - 1 + currentGalleryImages.length) % currentGalleryImages.length;
        updateGalleryImage();
    };

    const focusNextElement = (): void => {
        currentFocusIndex = (currentFocusIndex + 1) % focusableElements.length;
        focusableElements[currentFocusIndex].focus();
    };

    const focusPrevElement = (): void => {
        currentFocusIndex = (currentFocusIndex - 1 + focusableElements.length) % focusableElements.length;
        focusableElements[currentFocusIndex].focus();
    };

    document.querySelectorAll<HTMLElement>('.text-image__photo-gallery').forEach((gallery) => {
        const galleryId = gallery.getAttribute('data-gallery-id');
        if (!galleryId) return;

        const galleryButtons = gallery.querySelectorAll<HTMLButtonElement>('.text-image__gallery-item');

        galleryButtons.forEach((button, index) => {
            button.addEventListener('click', () => openGalleryPopup(galleryId, index));
            button.addEventListener('keydown', (e: KeyboardEvent) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    openGalleryPopup(galleryId, index);
                }
            });
        });
    });

    closeButton.addEventListener('click', closeGalleryPopup);
    nextButton.addEventListener('click', showNextImage);
    prevButton.addEventListener('click', showPrevImage);

    galleryPopup.addEventListener('click', (e: MouseEvent) => {
        if (e.target === galleryPopup) {
            closeGalleryPopup();
        }
    });

    document.addEventListener('keydown', (e: KeyboardEvent) => {
        if (!galleryPopup.classList.contains('is-active')) return;

        switch (e.key) {
            case 'ArrowRight':
                showNextImage();
                break;
            case 'ArrowLeft':
                showPrevImage();
                break;
            case 'Escape':
                closeGalleryPopup();
                break;
            case 'Tab':
                e.preventDefault();
                if (e.shiftKey) {
                    focusPrevElement();
                } else {
                    focusNextElement();
                }
                break;
        }
    });

    galleryImage.addEventListener('touchstart', (e: TouchEvent) => {
        touchStartX = e.changedTouches[0].screenX;
    });

    galleryImage.addEventListener('touchend', (e: TouchEvent) => {
        touchEndX = e.changedTouches[0].screenX;
        handleSwipe();
    });

    const handleSwipe = (): void => {
        const swipeThreshold = 50;
        const swipeDistance = touchEndX - touchStartX;

        if (swipeDistance > swipeThreshold) {
            showPrevImage();
        } else if (swipeDistance < -swipeThreshold) {
            showNextImage();
        }
    };
});