import { initCircledFocus } from '@ts/utils/circled-focus';

document.addEventListener('DOMContentLoaded', function() {
    const profileMenuToggle = document.querySelector('[data-init-profile-menu]') as HTMLElement;

    if (profileMenuToggle) {
        const profileMenu = document.getElementById('profileMenu') as HTMLElement;
        const profileMenuClose = profileMenu.querySelector('.profile-menu__close') as HTMLElement;
        
        let removeCircledFocus: (() => void) | null = null;

        profileMenuToggle.addEventListener('click', function(event) {
            event.preventDefault();
            const isVisible = profileMenu.classList.toggle('visible');
            profileMenu.setAttribute('aria-hidden', String(!isVisible));
            profileMenuToggle.setAttribute('aria-expanded', String(isVisible));
            
            if (isVisible) {
                removeCircledFocus = initCircledFocus(profileMenu);
                profileMenu.querySelector('a')?.focus();
            } else if (removeCircledFocus) {
                removeCircledFocus();
                removeCircledFocus = null;
            }
        });

        profileMenuClose.addEventListener('click', function(event) {
            event.preventDefault();
            profileMenu.classList.remove('visible');
            profileMenu.setAttribute('aria-hidden', 'true');
            profileMenuToggle.setAttribute('aria-expanded', 'false');
            if (removeCircledFocus) {
                removeCircledFocus();
                removeCircledFocus = null;
            }
            profileMenuToggle.focus();
        });

        document.addEventListener('click', function(event) {
            const target = event.target as Node;
            if (!profileMenu.contains(target) && !profileMenuToggle.contains(target)) {
                profileMenu.classList.remove('visible');
                profileMenu.setAttribute('aria-hidden', 'true');
                profileMenuToggle.setAttribute('aria-expanded', 'false');
                if (removeCircledFocus) {
                    removeCircledFocus();
                    removeCircledFocus = null;
                }
            }
        });

        profileMenu.addEventListener('keydown', function(event) {
            if (event.key === 'Escape') {
                profileMenu.classList.remove('visible');
                profileMenu.setAttribute('aria-hidden', 'true');
                profileMenuToggle.setAttribute('aria-expanded', 'false');
                profileMenuToggle.focus();
                if (removeCircledFocus) {
                    removeCircledFocus();
                    removeCircledFocus = null;
                }
            }
        });
    }
});