import { initCircledFocus } from '@ts/utils/circled-focus';

document.addEventListener('DOMContentLoaded', function() {
    const languageSwitcherToggle = document.querySelector('[data-init-language-switcher]') as HTMLElement;

    if (languageSwitcherToggle) {
        const languageSwitcher = document.getElementById('languageSwitcher') as HTMLElement;
        const languageSwitcherClose = languageSwitcher.querySelector('[data-language-switcher-close]') as HTMLElement;
        
        let removeCircledFocus: (() => void) | null = null;

        languageSwitcherToggle.addEventListener('click', function(event) {
            event.preventDefault();
            const isVisible = languageSwitcher.classList.toggle('visible');
            languageSwitcher.setAttribute('aria-hidden', String(!isVisible));
            languageSwitcherToggle.setAttribute('aria-expanded', String(isVisible));
            
            if (isVisible) {
                removeCircledFocus = initCircledFocus(languageSwitcher);
                languageSwitcher.querySelector('a')?.focus();
            } else if (removeCircledFocus) {
                removeCircledFocus();
                removeCircledFocus = null;
            }
        });

        languageSwitcherClose.addEventListener('click', function(event) {
            event.preventDefault();
            languageSwitcher.classList.remove('visible');
            languageSwitcher.setAttribute('aria-hidden', 'true');
            languageSwitcherToggle.setAttribute('aria-expanded', 'false');
            if (removeCircledFocus) {
                removeCircledFocus();
                removeCircledFocus = null;
            }
            languageSwitcherToggle.focus();
        });

        document.addEventListener('click', function(event) {
            const target = event.target as Node;
            if (!languageSwitcher.contains(target) && !languageSwitcherToggle.contains(target)) {
                languageSwitcher.classList.remove('visible');
                languageSwitcher.setAttribute('aria-hidden', 'true');
                languageSwitcherToggle.setAttribute('aria-expanded', 'false');
                if (removeCircledFocus) {
                    removeCircledFocus();
                    removeCircledFocus = null;
                }
            }
        });

        languageSwitcher.addEventListener('keydown', function(event) {
            if (event.key === 'Escape') {
                languageSwitcher.classList.remove('visible');
                languageSwitcher.setAttribute('aria-hidden', 'true');
                languageSwitcherToggle.setAttribute('aria-expanded', 'false');
                languageSwitcherToggle.focus();
                if (removeCircledFocus) {
                    removeCircledFocus();
                    removeCircledFocus = null;
                }
            }
        });
    }
});
