import { translations } from './translations';

export function getCurrentLanguage(): string {
  const lang = document.documentElement.lang;
  return ['en', 'de', 'es'].includes(lang) ? lang : 'en';
}

export function translate(key: string): string {
  const lang = getCurrentLanguage();
  return translations[lang]?.[key] || translations['en'][key];
}
