import { initCircledFocus } from '@ts/utils/circled-focus';

document.addEventListener('DOMContentLoaded', () => {
    const nav = document.querySelector('[data-nav]') as HTMLElement;
    const burgerMenuButton = document.querySelector('[data-menu-toggle]') as HTMLElement;

    if (!nav || !burgerMenuButton) {
        return;
    }

    const navItems = nav.querySelectorAll('[data-has-dropdown]');
    const mediaQuery = window.matchMedia('(max-width: 1279px)');
    let removeCircledFocus: (() => void) | null = null;

    let isKeyboardNavigation = false;
    let isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    function handleDropdowns(isMobile: boolean) {
        navItems.forEach((item, index) => {
            const link = item.querySelector('a') as HTMLAnchorElement;
            const dropdown = item.querySelector('[data-dropdown]') as HTMLElement;

            // Remove all existing event listeners
            item.removeEventListener('mouseenter', onMouseEnter);
            item.removeEventListener('mouseleave', onMouseLeave);
            link.removeEventListener('click', onToggleClick);
            link.removeEventListener('click', onClickDesktop);
            link.removeEventListener('keydown', onKeyDown);

            link.setAttribute('aria-expanded', 'false');
            link.setAttribute('aria-haspopup', 'true');
            dropdown.id = `dropdown-${index}`;
            link.setAttribute('aria-controls', dropdown.id);

            if (dropdown && link) {
                if (isMobile || isTouchDevice) {
                    link.addEventListener('click', onToggleClick);
                } else {
                    item.addEventListener('mouseenter', onMouseEnter);
                    item.addEventListener('mouseleave', onMouseLeave);
                    link.addEventListener('click', onClickDesktop);
                }
                link.addEventListener('keydown', onKeyDown);
            }
        });
    }

    function onToggleClick(this: HTMLElement, e: Event) {
        e.preventDefault();
        const item = this.closest('[data-has-dropdown]') as HTMLElement;
        if (item) {
            const isOpen = item.classList.contains('open');
            closeAllDropdowns();
            if (!isOpen) {
                openDropdown(item);
            }
        }
    }

    function onClickDesktop(this: HTMLElement, e: Event) {
        const item = this.closest('[data-has-dropdown]') as HTMLElement;
        if (item) {
            if (item.classList.contains('open')) {
                // If dropdown is open, allow default action (navigation)
                return;
            } else {
                // If dropdown is closed, prevent default and open dropdown
                e.preventDefault();
                closeAllDropdowns();
                openDropdown(item);
            }
        }
    }

    function onMouseEnter(this: Element) {
        if (!mediaQuery.matches) { // Only for desktop view
            isKeyboardNavigation = false;
            closeAllDropdowns();
            openDropdown(this as HTMLElement);
        }
    }

    function onMouseLeave(this: Element) {
        if (!mediaQuery.matches) { // Only for desktop view
            closeDropdown(this as HTMLElement);
        }
    }

    function onKeyDown(this: HTMLElement, e: KeyboardEvent) {
        const item = this.closest('[data-has-dropdown]') as HTMLElement;
        const dropdown = item.querySelector('[data-dropdown]') as HTMLElement;

        isKeyboardNavigation = true;

        switch (e.key) {
            case 'Enter':
            case ' ':
                e.preventDefault();
                toggleDropdown(item);
                break;
            case 'Escape':
                e.preventDefault();
                if (item.classList.contains('open')) {
                    closeDropdown(item);
                } else {
                    closeAllDropdowns();
                }
                break;
            case 'ArrowDown':
                if (item.classList.contains('open')) {
                    e.preventDefault();
                    const firstDropdownItem = dropdown.querySelector('a') as HTMLElement;
                    firstDropdownItem?.focus();
                } else {
                    e.preventDefault();
                    openDropdown(item);
                }
                break;
        }
    }

    function openDropdown(item: HTMLElement) {
        closeAllDropdowns();
        item.classList.add('open');
        const link = item.querySelector('a') as HTMLAnchorElement;
        const dropdown = item.querySelector('[data-dropdown]') as HTMLElement;

        link.setAttribute('aria-expanded', 'true');
        dropdown.setAttribute('aria-hidden', 'false');

        if (removeCircledFocus) {
            removeCircledFocus();
        }

        removeCircledFocus = initCircledFocus(item);

        if (isKeyboardNavigation) {
            const firstDropdownItem = dropdown.querySelector('a') as HTMLElement;
            if (firstDropdownItem) {
                firstDropdownItem.focus();
            } else {
                link.focus();
            }
        }
    }

    function closeDropdown(item: HTMLElement, setfocus: boolean = false) {
        item.classList.remove('open');
        const link = item.querySelector('a') as HTMLAnchorElement;
        const dropdown = item.querySelector('[data-dropdown]') as HTMLElement;

        link.setAttribute('aria-expanded', 'false');
        dropdown.setAttribute('aria-hidden', 'true');

        if (removeCircledFocus) {
            removeCircledFocus();
            removeCircledFocus = null;
        }

        if (setfocus) {
            // Focus on the menu item that opened the dropdown
            link.focus();
        }
    }

    function toggleDropdown(item: HTMLElement) {
        if (item.classList.contains('open')) {
            closeDropdown(item);
        } else {
            openDropdown(item);
        }
    }

    function closeAllDropdowns() {
        navItems.forEach(item => {
            if (item.classList.contains('open')) {
                closeDropdown(item as HTMLElement);
            }
        });
    }

    handleDropdowns(mediaQuery.matches);

    // Update to use addEventListener
    mediaQuery.addEventListener('change', (e) => {
        closeAllDropdowns();
        handleDropdowns(e.matches);
    });

    document.addEventListener('click', (e) => {
        if (!(e.target as Element).closest('[data-nav]')) {
            closeAllDropdowns();
        }
    });

    // Add event listener for Escape key on document level
    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            const openDropdown = nav.querySelector('[data-has-dropdown].open') as HTMLElement;
            if (openDropdown) {
                closeDropdown(openDropdown, true);
            } else {
                closeAllDropdowns();
            }
        }
    });

    document.addEventListener('mousemove', () => {
        isKeyboardNavigation = false;
    });

    // Reinitialize dropdowns on window resize
    window.addEventListener('resize', () => {
        handleDropdowns(mediaQuery.matches);
    });

    return () => {
        if (removeCircledFocus) {
            removeCircledFocus();
        }
        mediaQuery.removeEventListener('change', () => {});
    };
});
