export const translations: { [key: string]: { [key: string]: string } } = {
    'en': {
        title: 'Do you want to stay connected?',
        message1: 'Your session has been inactive for a while. For your security, you will be logged out in',
        message2: 'seconds',
        stayConnected: 'Stay Connected',
        disconnect: 'Disconnect'
    },
    'de': {
        title: 'Möchten Sie verbunden bleiben?',
        message1: 'Ihre Sitzung war längere Zeit inaktiv. Zu Ihrer Sicherheit werden Sie in',
        message2: 'Sekunden abgemeldet',
        stayConnected: 'Verbunden bleiben',
        disconnect: 'Trennen'
    },
    'es': {
        title: '¿Desea permanecer conectado?',
        message1: 'Su sesión ha estado inactiva por un tiempo. Por su seguridad, se cerrará la sesión en',
        message2: 'segundos',
        stayConnected: 'Permanecer Conectado',
        disconnect: 'Desconectar'
    }
};
