document.addEventListener('DOMContentLoaded', () => {
    const popups = document.querySelectorAll('.video-popup');
    if (popups.length === 0) return; // Exit if no popups found

    const popupTitles = Array.from(popups).map(popup => popup.querySelector('.video-popup__title') as HTMLElement);
    const popupVideos = Array.from(popups).map(popup => popup.querySelector('.video-popup__player') as HTMLVideoElement);
    const closeButtons = Array.from(popups).map(popup => popup.querySelector('.video-popup__close') as HTMLElement);

    const openPopup = (e: Event) => {
        e.preventDefault();
        const link = e.currentTarget as HTMLAnchorElement;
        const videoUrl = link.dataset.videoUrl;
        const videoTitle = link.dataset.videoTitle;
        const popupIndex = Array.from(document.querySelectorAll('.text-image__video-link')).indexOf(link);

        if (videoUrl && videoTitle && popups[popupIndex]) {
            popupTitles[popupIndex].textContent = videoTitle;
            popupVideos[popupIndex].src = videoUrl;
            (popups[popupIndex] as HTMLElement).classList.add('is-active');

            if (window.innerWidth <= 768) {
                popupVideos[popupIndex].requestFullscreen().catch(err => {
                    console.error(`Error attempting to enable full-screen mode: ${err.message}`);
                });
            }
        }
    };

    const closePopup = (index: number) => {
        (popups[index] as HTMLElement).classList.remove('is-active');
        popupVideos[index].pause();
        popupVideos[index].currentTime = 0;
        popupVideos[index].src = '';
        
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    };

    // Set up event listeners
    document.querySelectorAll('.text-image__video-link').forEach(link => {
        link.addEventListener('click', openPopup);
    });

    closeButtons.forEach((button, index) => {
        button.addEventListener('click', () => closePopup(index));
    });

    popups.forEach((popup, index) => {
        popup.addEventListener('click', (e) => {
            if (e.target === popup) {
                closePopup(index);
            }
        });
    });
});