import { showWarning } from './modal';

declare const isLoggedIn: boolean;

let lastActivityTimestamp: number = Date.now();
const INITIAL_INACTIVITY_TIME: number = 9 * 60 * 1000; // 9 minutes in milliseconds
let inactivityTime: number = INITIAL_INACTIVITY_TIME;
let intervalId: number | null = null;

function updateLastActivity(): void {
  lastActivityTimestamp = Date.now();
}

function checkInactivity(): void {
  const currentTime = Date.now();
  const timeSinceLastActivity = currentTime - lastActivityTimestamp;

  if (timeSinceLastActivity >= inactivityTime) {
    if (intervalId !== null) {
      clearInterval(intervalId);
      intervalId = null;
    }
    showWarning();
  }
}

function startInactivityCheck(): void {
  if (intervalId !== null) {
    clearInterval(intervalId);
  }
  intervalId = setInterval(checkInactivity, 1000) as unknown as number; // Check every second the time difference
}

export function resetInactivityTimer(): void {
  inactivityTime = INITIAL_INACTIVITY_TIME; // Reset inactivityTime to initial value
  updateLastActivity();
  startInactivityCheck();
}

if (isLoggedIn) {
  document.removeEventListener('mousemove', updateLastActivity);
  document.removeEventListener('keypress', updateLastActivity);

  document.addEventListener('mousemove', updateLastActivity);
  document.addEventListener('keypress', updateLastActivity);

  resetInactivityTimer(); // Initialize the timer
}