import { initCircledFocus } from '../utils/circled-focus';

document.addEventListener('DOMContentLoaded', function () {
    const headerMenu = document.querySelector('[data-init-headermenu]');

    if (headerMenu) {
        const menuButton = headerMenu.querySelector('[data-menu-toggle]') as HTMLElement;
        const menuItems = headerMenu.querySelector('[data-menu-items]') as HTMLElement;

        let removeCircledFocus: (() => void) | null = null;

        const handleDocumentClick = (event: MouseEvent) => {
            const target = event.target as Node;
            if (!headerMenu.contains(target)) {
                hideMenu();
            }
        };

        const showMenu = () => {
            menuItems.style.display = 'flex';
            setTimeout(() => {
                menuItems.classList.add('visible');
                menuItems.style.display = '';
            }, 0);
            document.addEventListener('click', handleDocumentClick);
            menuItems.focus();
            removeCircledFocus = initCircledFocus(menuItems);
        };

        const hideMenu = () => {
            menuItems.style.display = 'flex';
            menuItems.classList.remove('visible');
            setTimeout(() => {
                menuItems.style.display = '';
            }, 300);
            menuButton.setAttribute('aria-expanded', 'false');
            document.removeEventListener('click', handleDocumentClick);
            if (removeCircledFocus) {
                removeCircledFocus();
                removeCircledFocus = null;
            }
        };

        menuButton.addEventListener('click', function () {
            const isExpanded = menuButton.getAttribute('aria-expanded') === 'true';

            if (!isExpanded) {
                showMenu();
                menuButton.setAttribute('aria-expanded', 'true');
            } else {
                hideMenu();
            }
        });

        menuButton.addEventListener('keydown', function (event: KeyboardEvent) {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                menuButton.click();
            }
        });

        menuItems.addEventListener('keydown', function (event: KeyboardEvent) {
            if (event.key === 'Escape') {
                const openDropdown = headerMenu.querySelector('[data-has-dropdown].open') as HTMLElement;
                if (!openDropdown) {
                    hideMenu();
                    menuButton.focus();
                }
            }
        });
    }
});