document.addEventListener('DOMContentLoaded', initializeAccordions);

function initializeAccordions() {
    const accordions: NodeListOf<Element> = document.querySelectorAll('[data-init-accordion]');

    accordions.forEach(setupAccordion);

    handleHash();

    window.addEventListener('hashchange', handleHash);
}

function scrollWithOffset(element: HTMLElement): void {
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.scrollY;
    const offsetPosition = absoluteElementTop - 100;
    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
    });
}

function monitorAndAdjustScroll(element: HTMLElement): void {
    let start: number | null = null;
    const duration = 300;

    function step(timestamp: number) {
        if (!start) start = timestamp;
        const elapsed = timestamp - start;

        const elementRect = element.getBoundingClientRect();
        const currentOffset = elementRect.top;

        if (currentOffset !== 100 && elapsed < duration) {
            scrollWithOffset(element);
            requestAnimationFrame(step);
        } else if (elapsed >= duration) {
            setTimeout(() => finalScrollAdjustment(element), 100);
        }
    }

    requestAnimationFrame(step);
}

function finalScrollAdjustment(element: HTMLElement): void {
    const finalRect = element.getBoundingClientRect();
    if (Math.abs(finalRect.top - 100) > 1) {
        scrollWithOffset(element);
    }
}

function openAccordionItemById(id: string): void {
    const targetElement = document.getElementById(id);
    if (targetElement) {
        const accordionItem = targetElement.closest('.accordion-item');
        if (accordionItem instanceof HTMLElement) {
            const button = accordionItem.querySelector('.accordion-button');
            if (button instanceof HTMLElement) {
                button.click();
            }
            monitorAndAdjustScroll(accordionItem);
        }
    }
}

function handleHash(): void {
    if (location.hash) {
        const id = location.hash.substring(1);
        openAccordionItemById(id);
    }
}

function setupAccordion(accordion: Element): void {
    const items: NodeListOf<Element> = accordion.querySelectorAll('.accordion-item');
    const buttons: NodeListOf<Element> = accordion.querySelectorAll('.accordion-button');

    buttons.forEach((button, index) => {
        button.addEventListener('click', (event) => handleAccordionItemClick(event, items, index));
    });

    // Set initial tabindex for all gallery grid elements except the first one
    items.forEach((item, index) => {
        if (index === 0) {
            setGalleryGridTabIndex(item as HTMLElement, 0);
        } else {
            setGalleryGridTabIndex(item as HTMLElement, -1);
        }
    });
}

function handleAccordionItemClick(event: Event, items: NodeListOf<Element>, index: number): void {
    event.preventDefault();
    const item = items[index] as HTMLElement;
    const button = item.querySelector('.accordion-button') as HTMLElement;
    const isExpanded = button.getAttribute('aria-expanded') === 'true';

    closeAllAccordionItems(items);

    if (!isExpanded) {
        openAccordionItem(item, button);
    }
}

function closeAllAccordionItems(items: NodeListOf<Element>): void {
    items.forEach(i => {
        const currentItem = i as HTMLElement;
        currentItem.classList.remove('open');
        const collapse = currentItem.querySelector('.accordion-collapse') as HTMLElement;
        const btn = currentItem.querySelector('.accordion-button') as HTMLElement;
        collapse.style.maxHeight = '0px';
        collapse.classList.remove('show');
        btn.classList.add('collapsed');
        btn.setAttribute('aria-expanded', 'false');
        setGalleryGridTabIndex(currentItem, -1);
    });
}

function openAccordionItem(item: HTMLElement, button: HTMLElement): void {
    const images = item.querySelectorAll('img[loading="lazy"]');
    
    if (images.length > 0) {
        images.forEach(img => img.removeAttribute('loading'));
        
        setTimeout(() => {
            openAccordionItemCore(item, button);
        }, 100);
    } else {
        openAccordionItemCore(item, button);
    }
}

function openAccordionItemCore(item: HTMLElement, button: HTMLElement): void {
    item.classList.add('open');
    const collapse = item.querySelector('.accordion-collapse') as HTMLElement;
    collapse.style.maxHeight = `${collapse.scrollHeight}px`;
    collapse.classList.add('show');
    button.classList.remove('collapsed');
    button.setAttribute('aria-expanded', 'true');
    setGalleryGridTabIndex(item, 0);
    monitorAndAdjustScroll(item);
}

function setGalleryGridTabIndex(item: HTMLElement, tabIndex: number): void {
    const galleryGrid = item.querySelector('.accordion-body');
    if (galleryGrid) {
        const interactiveElements = galleryGrid.querySelectorAll('a, button');
        interactiveElements.forEach(element => {
            element.setAttribute('tabindex', tabIndex.toString());
        });
    }
}